.a-payment {
  z-index: 20;
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 6px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: #283046;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  .close {
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 6px;
    background: #283046;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__content {
    background: red;
  }
}

.payment {
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  background: #161d31;

  &.closed {
    display: none;
  }

  &__info {
    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;

      color: #d0d2d6;
      margin-bottom: 8px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      color: #b4b7bd;
    }

    .t-red {
      color: #ea5455;
    }
  }

  .packages {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    list-style: none;
    li button {
      cursor: pointer;
      min-width: 160px;
      background: #283046;
      border: 1px solid #3b4253;
      border-radius: 640px;
      padding: 8px 14px;

      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .card {
    margin-top: 16px;

    &__package {
      background: #283046;
      padding: 8px 16px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      .pack {
        &:nth-child(1) {
          margin-bottom: 8px;
        }

        display: flex;
        justify-content: space-between;

        p {
          color: #b4b7bd;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }

        .price {
          color: #6e6b7b;
        }
        .time {
          color: #28c76f;
        }
      }
    }

    &__form {
      margin-top: 16px;

      .forgot-pass {
        display: flex;
        justify-content: space-between;

        button {
          background: unset;
          border: unset;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */

          text-align: right;

          /* 1) Theme Color/Primary */

          color: #7367f0;
          cursor: pointer;
        }
      }

      label {
        font-style: normal;
        color: #babfc7;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        span {
          color: #ea5455;
        }
      }

      .input {
        margin-top: 5px;
        background: #283046;
        border: 1px solid #3b4253;
        border-radius: 5px;
        padding: 8px 14px;

        input {
          background: transparent;
          border: unset;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #fff;

          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #676d7d;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .date {
        margin-top: 5px;
        display: flex;
        gap: 8px;

        .select__wraper {
          background: #283046;
          border: 1px solid #3b4253;
          border-radius: 5px;
          position: relative;
          width: 100%;

          select {
            border: none;
            background: transparent;
            padding: 8px 14px;

            height: 100%;
            font-family: 'Montserrat';
            width: 100%;
            color: #676d7d;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;

            &:focus {
              outline: none;
            }

            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
          }

          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            display: block;
            content: '';
            background: url('../images/arrow-select.png') no-repeat center;
            width: 14px;
            height: 14px;
          }
        }
      }

      .error {
        border: 1px solid red !important;
      }
    }

    .lock {
      margin-top: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      color: #b4b7bd;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 4px;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    button {
      cursor: pointer;
      padding: 10px 22px;
      letter-spacing: 0.4px;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    .back {
      background: transparent;
      border: 1px solid #82868b;
      color: #82868b;
    }

    .next {
      margin-left: auto;
      border: 1px solid #7367f0;
      background: #7367f0;
      color: #ffffff;
    }
  }

  .or {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #b4b7bd;

    display: flex;
    align-items: center;
    gap: 16px;

    margin: 24px 0;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #3b4253;
    }
  }

  .google_btn {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #3c4043;
    display: flex;
    justify-content: center;

    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
  }

  .loading-email {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999;
  }
  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .fail {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .wrong {
      background: rgba(234, 84, 85, 0.12);
      border-radius: 4px;
      p {
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;

        color: #ea5455;
      }
      p:nth-child(1) {
        font-weight: 700;
        padding: 6px;
      }
      p:nth-child(2) {
        font-weight: 400;
        padding: 10px;
        background: linear-gradient(
          180deg,
          rgba(234, 84, 85, 0.16) 0%,
          rgba(234, 84, 85, 0) 104.59%
        );
      }
    }

    img {
      display: block;
      margin: 44px auto;
    }

    &__info {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* or 150% */

        color: #b4b7bd;
      }

      p:nth-child(1) {
        margin-bottom: 16px;
      }
    }

    .try-again {
      cursor: pointer;
      margin: 0 auto;
      margin-top: auto;
      background: #7367f0;
      border-radius: 5px;
      padding: 10px 22px;
      max-width: 113px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      text-align: center;
      letter-spacing: 0.4px;

      color: #ffffff;
    }
  }
}

.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 16px;
  .inner {
    background: #283046;
    width: 100%;
    height: 100%;
    /* Default Shadow */

    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 16px;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        /* identical to box height */

        text-align: center;

        /* 1) Theme Color / Heading & Display - Text */

        color: #d0d2d6;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* or 150% */

        text-align: center;

        /* 1) Theme Color / Body - Text */

        color: #b4b7bd;
      }

      .reset-pass {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &__btns {
        display: flex;
        justify-content: space-between;

        button {
          cursor: pointer;
          padding: 10px 22px;
          letter-spacing: 0.4px;
          border-radius: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }

        .cancel {
          background: transparent;
          border: 1px solid #7367f0;
          color: #7367f0;
        }

        .success {
          margin-left: auto;
          border: 1px solid #7367f0;
          background: #7367f0;
          color: #ffffff;
        }
      }
    }
  }
}
