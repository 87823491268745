.widget-modal {
  z-index: 99999999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.2);
  padding: 24px;

  &.hide {
    display: none;
  }

  &__box {
    border-radius: 6px;
    background: #283046;
    position: relative;
    height: 100%;
    padding: 24px 16px;

    &-close {
      position: absolute;
      top: -12px;
      right: -12px;
      padding: 6px;
      background: #283046;
      border: none;
      border-radius: 6px;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &__content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// Simple modal
.simple-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 26px;
      /* identical to box height */

      text-align: center;

      /* 1) Theme Color / Heading & Display - Text */

      color: #d0d2d6;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      text-align: center;

      /* 1) Theme Color / Body - Text */

      color: #b4b7bd;
    }
  }
}

// Payment modal
.payment-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #d0d2d6;
    font-feature-settings: 'clig' off, 'liga' off;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
  }
}

.payment-package {
  .packages {
    overflow: hidden;
  }

  .payment-packs {
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    padding-bottom: 8px;

    // Scrollbar
    &::-webkit-scrollbar {
      height: 8px;
      border-radius: 640px;
      border: 1px solid #82868b;
      background: #1d2336;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 640px;
      border: 1px solid #d0d2d6;
      background: #d0d2d6;
    }

    .pack {
      cursor: pointer;
      white-space: nowrap;
      padding: 16px;
      border-radius: 6px;
      border: 1px solid #82868b;
      background: rgba(130, 134, 139, 0.06);

      h4 {
        color: #82868b;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
      }

      h5 {
        color: #fff;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
      }

      h6 {
        color: #fff;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .pack.selected {
      border: 1px solid #28c76f;
      background: rgba(40, 199, 111, 0.06);
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);

      h4 {
        color: #28c76f;
      }
    }
  }

  .choose {
    margin-top: 16px;
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .input {
    position: relative;
    margin-top: 8px;
    margin-bottom: 4px;
    input {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #3b4253;
      background: #283046;

      color: #676d7d;
      text-align: left;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */

      padding: 8px 14px;

      &:focus {
        outline: none;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0; /* Optional - removes any remaining margin */
      }
    }

    p {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);

      color: #fff;
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    div {
      width: 100%;
      border-radius: 5px;
      border: 1px solid #283046;
      background: #1f263b;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;

      color: #676d7d;
      font-feature-settings: 'clig' off, 'liga' off;

      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.payment-cards {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #3b4253;
    padding: 8px;
    cursor: pointer;

    &.selected {
      border: 1px solid #28c76f;
    }

    span {
      color: #b4b7bd;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }

    img {
      max-width: 48px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    button {
      margin-left: auto;
      width: min-content;
      cursor: pointer;
      background: transparent;
      border: none;

      color: #7367f0;
      text-align: right;
      font-feature-settings: 'clig' off, 'liga' off;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.4px;
      padding: 8px 16px;
    }
  }
}
// End payment modal

// Wait modal
.wait-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  h3,
  h4 {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #d0d2d6;
    font-feature-settings: 'clig' off, 'liga' off;
  }
  h4 {
    color: #ea5455;
  }
  p {
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// Creadit card
.credit-card-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: #d0d2d6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }
}

.credit-card {
  margin-top: 16px;

  &__package {
    background: #283046;
    padding: 8px 16px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    .pack {
      &:nth-child(1) {
        margin-bottom: 8px;
      }

      display: flex;
      justify-content: space-between;

      p {
        color: #b4b7bd;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .price {
        color: #6e6b7b;
      }
      .time {
        color: #28c76f;
      }
    }
  }

  &__form {
    margin-top: 16px;

    .forgot-pass {
      display: flex;
      justify-content: space-between;

      button {
        background: unset;
        border: unset;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-align: right;

        /* 1) Theme Color/Primary */

        color: #7367f0;
        cursor: pointer;
      }
    }

    label {
      font-style: normal;
      color: #babfc7;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      span {
        color: #ea5455;
      }
    }

    .input {
      margin-top: 5px;
      background: #283046;
      border: 1px solid #3b4253;
      border-radius: 5px;
      padding: 8px 14px;

      input {
        background: transparent;
        border: unset;
        width: 100%;
        height: 100%;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #fff;

        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #676d7d;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .date {
      margin-top: 5px;
      display: flex;
      gap: 8px;

      .select__wraper {
        background: #283046;
        border: 1px solid #3b4253;
        border-radius: 5px;
        position: relative;
        width: 100%;

        select {
          border: none;
          background: transparent;
          padding: 8px 14px;

          height: 100%;
          font-family: 'Montserrat';
          width: 100%;
          color: #676d7d;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;

          &:focus {
            outline: none;
          }

          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
        }

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
          display: block;
          content: '';
          background: url('../images/arrow-select.png') no-repeat center;
          width: 14px;
          height: 14px;
        }
      }
    }

    .error {
      border: 1px solid red !important;
    }
  }

  .lock {
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #b4b7bd;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 4px;
    }
  }
}

// Call ended
.call-ended-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: #d0d2d6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }

  textarea {
    padding: 8px 16px;
    border-radius: 6px;
    border: 1px solid #404656;
    background: #161d31;
    width: 100%;
    height: 88px;

    resize: none;

    color: #676d7d;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Placeholder / Placeholder md */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent; /* Optional: This removes the gray background on Webkit browsers */
    }
  }

  .stars {
    display: flex;
    justify-content: center;
    gap: 4px;
  }
}

// Minutes alert
.minutes-alert-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  h3 {
    color: #d0d2d6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: #b4b7bd;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 24px;
    span {
      color: #ea5455;
    }
  }

  img {
    max-width: 196px;
    margin: 0 auto;
  }
}
