.videoCall-modal {
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);

  &.show {
    opacity: 1;
    pointer-events: unset;
    transform: translateY(0);
    transition: all 0.5s ease-in;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transform: translateY(100%);
    transition: all 0.5s ease-in;
  }

  &.collapse {
    position: fixed !important;

    .videoCall__chat {
      width: 25%;

      .chat-header button {
        display: none;
      }

      &.none {
        transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
        width: 0;
        overflow: hidden;
      }
    }
  }

  .collapse-btn {
    z-index: 90;
    position: absolute;
    right: 3px;
    top: 3px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.videoCall-ringing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #161d31;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 320px;
  border-radius: 16px;

  p {
    color: #fff;
    font-size: 40px;
    margin-bottom: 16px;
  }

  .btns {
    button {
      width: 100%;
      margin-top: 8px;
      padding: 12px;
    }
  }
}

.videoCall {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #161d31;
  display: flex;
  overflow: hidden;

  &__call {
    flex: 1;
    position: relative;
    transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
    width: 100% !important;
    height: 100% !important;

    .videosBox {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;

      .ringing.none,
      .audio.none,
      .video.none {
        display: none !important;
      }

      .partner {
        background: #161d31;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .ringing {
          display: flex;
          flex-direction: column;
          align-items: center;

          &_box {
            background: #283046;
            border: 1px solid #3b4253;
            border-radius: 10px;
            max-width: 240px;
            width: 100%;
            padding: 56px 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              display: block;
              margin: 0 auto;
              width: 100%;
            }
          }

          p {
            color: #7367f0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
          }
        }

        .video {
          width: 100%;
          height: 100%;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .local {
        border-radius: 10px;
        background: #161d31;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden !important;
        position: relative;

        &:hover .size {
          opacity: 1;
          transition: all 0.5s;
        }
        &:hover {
          background: #000;
          transition: all 0.5s;
        }
        &:hover .video {
          opacity: 0.7;
          transition: all 0.5s;
        }

        .size {
          position: absolute;
          top: 8px;
          right: 8px;
          background: none;
          border: none;
          color: #fff;
          cursor: pointer;
          z-index: 10;
          opacity: 0;
        }

        .audio {
          background: #283046;
          border: 1px solid #3b4253;
          border-radius: 10px;
          max-width: 240px;
          width: 100%;
          height: 100%;
          padding: 56px 36px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            display: block;
            margin: 0 auto;
            width: 100%;
          }
        }

        .video {
          overflow: hidden;
          width: 100%;
          height: 100%;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      // Small version
      &.v-small {
        .partner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
        }

        .local {
          position: absolute;
          bottom: 32px;
          right: 32px;
          width: 174px;
          height: 124px;

          transition: width 0.3s linear, height 0.3s linear;

          @media screen and (max-width: 870px) {
            bottom: 125px;
            right: 6px;
          }

          bottom: 125px;
          right: 6px;

          .init {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.v-expanded {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
          position: relative;
        }

        flex-direction: column;
        position: relative;

        .partner,
        .local {
          border-radius: 10px;
          margin: 16px 8px;
          width: 50%;
          height: 90vh;
          transition: width 0.6s ease-in-out, height 0.6s ease-in-out;

          @media screen and (max-width: 1024px) {
            width: 90%;
            height: 100%;
          }

          width: 90%;
          height: 100%;
        }

        .local .audio {
          height: unset;
        }
      }
    }

    .call-nav {
      z-index: 5;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      background: rgba(186, 191, 199, 0.12);
      border-radius: 8px;
      padding: 0 24px;
      .nav {
        margin-top: 16px;
        list-style: none;
        display: flex;
        gap: 16px;

        @media screen and (max-width: 450px) {
          gap: 14px;
          .screenSharing {
            display: none;
          }
        }

        gap: 14px;
        .screenSharing {
          display: none;
        }

        button {
          background: unset;
          border: unset;

          border-radius: 50%;
          border: 1px solid #3b4253;
          width: 40px;
          height: 40px;
          cursor: pointer;
          color: #fff;
          position: relative;
          padding: 20px;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .end-call {
          background: #ea5455;
        }
      }

      .timer {
        margin: 8px 0;
        padding-top: 8px;
        border-top: 1px solid #3b4253;
        text-align: center;

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;

          color: #b4b7bd;

          span {
            font-weight: 700;
            color: #ffffff;
          }
        }
      }
    }
  }

  &__chat {
    &.none {
      transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
      width: 0;
      overflow: hidden;
    }

    z-index: 999;
    width: 100%;
    height: 100%;
    transition: width 0.6s ease-in-out, height 0.6s ease-in-out;
    overflow: unset;

    display: flex;
    flex-direction: column;
    height: 100%;

    padding-bottom: 16px;
    background: #161d31;

    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        border: none;
        background: #28c76f;
        border-radius: 5px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.4px;

        color: #ffffff;
        padding: 10px 22px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }
}

.acceptedOnAnotherDevice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: red;
  padding: 16px;
  border-radius: 16px;

  p {
    font-size: 22px;
  }
}
