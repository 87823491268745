.login {
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 13;
  background: #161d31;

  &.closed {
    display: none !important;
  }

  &__info {
    h2 {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;

      color: #d0d2d6;
      margin-bottom: 8px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      color: #b4b7bd;
    }

    .t-red {
      color: #ea5455;
    }
  }

  .card {
    margin-top: 16px;

    &__package {
      background: #283046;
      padding: 8px 16px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      .pack {
        &:nth-child(1) {
          margin-bottom: 8px;
        }

        display: flex;
        justify-content: space-between;

        p {
          color: #b4b7bd;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }

        .price {
          color: #6e6b7b;
        }
        .time {
          color: #28c76f;
        }
      }
    }

    &__form {
      margin-top: 16px;

      label {
        font-style: normal;
        color: #babfc7;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

        span {
          color: #ea5455;
        }
      }

      .input {
        margin-top: 5px;
        background: #283046;
        border: 1px solid #3b4253;
        border-radius: 5px;
        padding: 8px 14px;

        input {
          background: transparent;
          border: unset;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #fff;

          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #676d7d;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .date {
        margin-top: 5px;
        display: flex;
        gap: 8px;

        .select__wraper {
          background: #283046;
          border: 1px solid #3b4253;
          border-radius: 5px;
          position: relative;
          width: 100%;

          select {
            border: none;
            background: transparent;
            padding: 8px 14px;

            height: 100%;
            font-family: 'Montserrat';
            width: 100%;
            color: #676d7d;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;

            &:focus {
              outline: none;
            }

            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
          }

          &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 5px;
            display: block;
            content: '';
            background: url('../images/arrow-select.png') no-repeat center;
            width: 14px;
            height: 14px;
          }
        }
      }

      .error {
        border: 1px solid red !important;
      }
    }

    .lock {
      margin-top: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      color: #b4b7bd;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 4px;
      }
    }
  }

  .btn {
    cursor: pointer;
    padding: 10px 22px;
    letter-spacing: 0.4px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #7367f0;
    background: #7367f0;
    color: #ffffff;
    max-width: 86px;
    margin: 0 auto;
    margin-top: 24px;
  }

  .or {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    color: #b4b7bd;

    display: flex;
    align-items: center;
    gap: 16px;

    margin: 24px 0;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #3b4253;
    }
  }

  .google_btn {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #3c4043;
    display: flex;
    justify-content: center;

    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
  }

  .loading-email {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999;
  }
  .loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      animation: spin 3s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.forgot-pass {
  display: flex;
  justify-content: space-between;

  button {
    background: unset;
    border: unset;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: right;

    /* 1) Theme Color/Primary */

    color: #7367f0;
    cursor: pointer;
  }
}

.logout__btns {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .success {
    margin-left: unset !important;
    margin-right: auto;
  }

  .cancel {
    color: #ea5455 !important;
    border-color: #ea5455 !important;
  }
}

.logout {
  &.closed {
    display: none;
  }
}
