@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  // background: rgba(29, 52, 158, 0.263);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background: #283046;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.widget {
  &.no-open {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.open {
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-height: 600px) {
      height: 95vh;
    }
  }

  .widget-icon {
    border: none;
    background: unset;
    cursor: pointer;
  }

  .widget-container {
    background: #161d31;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 400px;

    .header {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      background: #7367f0;
      padding: 10px 8px;
      width: 100%;
      border: none;

      position: relative;
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */

        color: #ffffff;
        text-align: center;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .login-btn {
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 5px;
        padding: 10px 22px;
        color: #ffffff;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        letter-spacing: 0.4px;
        cursor: pointer;
      }

      .down {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }

    main {
      position: relative;
      max-height: calc(600px - 60px);
      min-height: calc(600px - 60px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    main::-webkit-scrollbar {
      display: none;
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 24px 16px;

      .avatar {
        position: relative;
        span {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }

        .online {
          background: #28c76f;
        }

        .offline {
          background-color: #82868b;
        }
        .away {
          background: rgb(208, 147, 25);
        }

        .busy {
          background: #8b0000;
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        /* identical to box height, or 160% */

        color: #d0d2d6;
      }

      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        /* identical to box height, or 164% */

        color: #b4b7bd;
      }
    }

    .video-chat {
      background: #fff;
      height: 100%;
      overflow: hidden;
      flex: 1;
    }

    .footer {
      display: flex;
      justify-content: space-around;
      padding: 16px;

      button {
        background: unset;
        border: unset;

        border-radius: 50%;
        border: 1px solid #7367f0;
        width: 38px;
        height: 38px;
        cursor: pointer;
        color: #7367f0;
        position: relative;
        padding: 10px;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.active {
          background: #7367f0;
          color: #fff;
        }
      }
    }
  }
}

.talk {
  background: #161d31;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #7367f0;
    margin-bottom: 32px;
  }

  .c-button {
    min-width: 240px;
    border: none;
    padding: 11.5px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    cursor: pointer;

    &.video {
      background: #7367f0;
      color: #ffffff;
      border: 1px solid #7367f0;
    }

    &.audio {
      background: transparent;
      color: #7367f0;
      border: 1px solid #7367f0;
    }
  }

  .founds {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
      color: #b4b7bd;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    button {
      margin: 0;
      border-radius: 5px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.16);
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 160% */
      cursor: pointer;
    }
  }
}

// Chat
.chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  height: 100%;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem;

  box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0rem 2rem 4rem -3rem rgba(black, 0.5);

  .contact.bar {
    flex-basis: 3.5rem;
    flex-shrink: 0;
    margin: 1rem;
    box-sizing: border-box;
  }

  .messages {
    flex: 1;
    // Hide ScrollBar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    height: 100%;
    padding: 1rem;
    background: #161d31;
    flex-shrink: 2;
    overflow-y: auto;

    box-shadow: inset 0 2rem 2rem -2rem rgba(black, 0.05),
      inset 0 -2rem 2rem -2rem rgba(black, 0.05);

    .message-box {
      margin-bottom: 8px;

      .message {
        box-sizing: border-box;
        padding: 0.5rem 1rem;

        min-height: 2.25rem;
        width: fit-content;
        max-width: 66%;

        background: #283046;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 5px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #b4b7bd;

        .typing {
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0rem;
          box-sizing: border-box;
          background: #ccc;
          border-radius: 50%;

          &.typing-1 {
            animation: typing 3s infinite;
          }
          &.typing-2 {
            animation: typing 3s 250ms infinite;
          }
          &.typing-3 {
            animation: typing 3s 500ms infinite;
          }
        }
      }

      .time {
        color: rgb(51, 48, 60);
        opacity: 0.38;
        font-size: 0.75rem;
      }

      &.me {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .message {
          background: linear-gradient(46.62deg, #7367f0 0%, #9e95f5 93.64%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
          border-radius: 5px;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }
    }
  }
  .send-message {
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    padding: 0 16px;
    background: #161d31;

    input {
      flex-grow: 2;

      background: #283046;
      border: 1px solid #3b4253;
      border-radius: 5px;
      padding: 8px 16px;

      color: #b9b9c3;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;

      &::placeholder {
        color: #b9b9c3;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
      }

      &:focus {
        outline: none;
      }
    }

    button {
      width: 97px;
      flex-grow: 1;
      border: none;
      cursor: pointer;
      background: #7367f0;
      border-radius: 5px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.4px;

      color: #ffffff;
    }
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }

  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}

.timer-chat {
  padding: 16px;
  margin: 8px 0;
  border-top: 1px solid #3b4253;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

    color: #b4b7bd;

    span {
      margin-left: 4px;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .end {
    border: none;
    background: #ea5455;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.no-active-widget {
  position: absolute;
  background: #4b4b4bbd;
  height: 100%;
  z-index: 9999;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: #8d83f9;

  button {
    margin-top: 24px;
    min-width: 240px;
    border: none;
    padding: 11.5px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
    cursor: pointer;

    background: #7367f0;
    color: #ffffff;
    border: 1px solid #7367f0;
  }
}

.call-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  .call {
    width: max-content;
    padding: 8px;
    flex-direction: column;
    border-radius: 16px;
    background: #ccccccaf;
    text-align: center;
  }
}

.btns {
  display: flex;
  justify-content: space-between;

  &.single {
    justify-content: center;
  }
}

.btn-primary {
  border: none;
  border-radius: 5px;
  background: #7367f0;
  border: 1px solid #7367f0;

  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;

  padding: 8px 16px;
  cursor: pointer;
}

.btn-secondary {
  border: none;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #7367f0;
  color: #7367f0;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;

  padding: 8px 16px;
  cursor: pointer;
  &.grey {
    color: #82868b;
    border-color: #82868b;
  }

  &.danger {
    color: #ea5455;
    border-color: #ea5455;
  }
}
